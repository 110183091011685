import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { User } from '../../../types/user';
import { Tooltip } from '../Tooltip';

export interface UserFullnameProps {
    firstName: User['firstName'];
    lastName: User['lastName'];
    isDeleted: boolean;
    dataTestid?: string;
    htmlAttributes?: HTMLAttributes<HTMLSpanElement>;
}

export const UserFullname = ({ firstName, lastName, isDeleted, dataTestid, htmlAttributes }: UserFullnameProps) => {
    const { t } = useTranslation();

    if (isDeleted) {
        return (
            <Tooltip
                content={t('Deleted user')}
                dataTestid='user-fullname-tooltip'
            >
                <span
                    className='text-gray-500'
                    data-testid={dataTestid}
                    {...htmlAttributes}
                >
                    {firstName} {lastName}
                </span>
            </Tooltip>
        );
    }
    return (
        <span
            data-testid={dataTestid}
            {...htmlAttributes}
        >
            {firstName} {lastName}
        </span>
    );
};
