import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

const SignInPage = async () =>
    import('@spacefill/external-console/src/pages/SignIn').then((module) => ({
        Component: module.SignInPage,
    }));
const MfaIntroPage = async () =>
    import('@spacefill/external-console/src/pages/MfaIntroPage').then((module) => ({
        Component: module.MfaIntroPage,
    }));
const MfaLoginPage = async () =>
    import('@spacefill/external-console/src/pages/MfaLoginPage').then((module) => ({
        Component: module.MfaLoginPage,
    }));
const MfaSetupPage = async () =>
    import('@spacefill/external-console/src/pages/MfaSetupPage').then((module) => ({
        Component: module.MfaSetupPage,
    }));
const ResetPassword = async () =>
    import('@spacefill/shared/src/pages/ResetPassword').then((module) => ({
        Component: module.ResetPassword,
    }));
const ChangePassword = async () =>
    import('@spacefill/external-console/src/pages/ChangePassword').then((module) => ({
        Component: module.ChangeForgetPassword,
    }));
const EnvView = async () =>
    import('@spacefill/shared/src/pages/Env').then((module) => ({
        Component: module.EnvView,
    }));
const SignUp = async () => import('../pages/SignUp').then((module) => ({ Component: module.SignUp }));

const RedirectToChangePasswordWithQueryParams = () => {
    const navigate = useNavigate();
    const location = useLocation();
    navigate(`/change-password/${location.search}`);

    return null;
};

const DefaultNavigateForUnknownUser = () => {
    const locationPathName = window?.location?.pathname;
    return (
        <Navigate
            to={
                locationPathName && locationPathName !== '/' && !locationPathName.includes('logout')
                    ? `/sign-in/?next-url=${encodeURIComponent(locationPathName)}`
                    : '/sign-in/'
            }
        />
    );
};

export function getUnloggedUserRoutes() {
    return [
        {
            path: '/mfa-login/',
            lazy: MfaLoginPage,
            redirectFrom: '/sign-in',
            element: <Outlet />,
        },
        {
            path: '/mfa-intro/',
            lazy: MfaIntroPage,
            redirectFrom: '/connexion',
            element: <Outlet />,
        },
        {
            path: '/mfa-setup/',
            lazy: MfaSetupPage,
            redirectFrom: '/mfa-intro/',
            element: <Outlet />,
        },
        {
            path: '/sign-in/',
            lazy: SignInPage,
            redirectFrom: '/connexion',
            element: <Outlet />,
        },
        {
            path: '/sign-up/',
            lazy: SignUp,
            element: <Outlet />,
        },
        {
            path: '/creer-mot-de-passe',
            element: <Navigate to='/sign-up/' />,
        },
        {
            path: '/reset-password/',
            lazy: ResetPassword,
            element: <Outlet />,
        },
        {
            path: '/password/edit',
            element: <RedirectToChangePasswordWithQueryParams />,
        },
        {
            path: '/change-password/',
            lazy: ChangePassword,
            element: <Outlet />,
        },
        // Version
        {
            path: '/environment/',
            lazy: EnvView,
            element: <Outlet />,
        },
        {
            path: '*',
            element: <DefaultNavigateForUnknownUser />,
        },
    ];
}
