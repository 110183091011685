import React from 'react';
import { useRouteError } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { NotFound } from '@spacefill/uikit/src/components/NotFound';
import { Button } from '@spacefill/uikit/src/components/Button';
import { getEnvironmentVariable } from '@spacefill/shared/src/utils/GetEnvVar';
import { datadogRum } from '@datadog/browser-rum';

export function getLocalStorageData() {
    const localStorageData = {};
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        localStorageData[key] = localStorage.getItem(key);
    }
    return localStorageData;
}

export const ErrorComponent = () => {
    const error = useRouteError();
    const { t } = useTranslation();
    const externalConsoleServiceUrl = getEnvironmentVariable('externalConsoleServiceUrl');

    const localStorageData = getLocalStorageData();
    datadogRum.addAction('localstorage', JSON.stringify(localStorageData));

    return (
        <div className='h-full overflow-auto'>
            <NotFound
                errorCode=''
                errorMessage={t('An error occurred')}
                details={
                    <pre className='p-4 my-2 font-["Courier"] leading-5 bg-violet-100 rounded-md'>
                        {error.message + '\n' + error.stack}
                    </pre>
                }
                cta={
                    <Button onClick={() => (window.location.href = externalConsoleServiceUrl)}>
                        {t('Return to the home page')}
                    </Button>
                }
            />
        </div>
    );
};
